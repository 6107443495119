<template>
	<div class="hi-footer">
		<!-- 关于我们 -->
		<div class="hi-about-section">
			<div class="hi-about-box">
				<!-- 标题 -->
				<div class="hi-about-title">
					关于我们
				</div>
				<!-- 内容 -->
				<div class="hi-about-main">
					<div v-html="item.about_html"></div>
				</div>
				<!--  -->
				<div class="hi-about-cell">
					<div @click="$hi.toBlank('https://www.gsxt.gov.cn')">
						<img src="@/assets/imgs/foots/gongshang.png" alt="" />
						工商登记信息
					</div>
					
					<div @click="$hi.toBlank('http://www.bjcredit.org.cn')">
						信用机构备案信息
					</div>
				</div>
				<!--  -->
				<div class="hi-about-cell">
					<div @click="$hi.toTel(item.web_phone)">
						联系电话：400-811-8213
					</div>
					
					<div>
						公众号：企讯猫
					</div>
				</div>
				<!--  -->
				<div class="hi-about-text">
					企讯猫友情提示：加入诚信万企工程无需验证码等相关信息 ，如需填写银行卡号、验证码、身份证号等相关信息，请勿相信，防止上当受骗！
				</div>
				
			</div>
		</div>
		<!-- 备案 -->
		<div class="hi-record-section">
			保定珉玉企业管理咨询有限公司
			备案号：<a href="https://beian.miit.gov.cn" target="_blank">冀ICP备2024085815号-1</a>
			<br>
			<!-- <img class="foot-icon" src="@/assets/imgs/foots/jing.png" alt="" /> -->
			<!-- <a href="https://beian.mps.gov.cn" target="_blank">冀ICP备2024085815号-1</a> -->
			
			<div style="width: 100%;height: 3.3rem;"></div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		props: {
			// 距离底部距离
			mbottom: {
				type: [String, Number],
				default: 0
			},
			// 内容数据
			item: {
				default: () => []
			}
		},
		methods: {
			
		}
	}
</script>

<style>
	
</style>
